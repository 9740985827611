import { DYNAMIC_RENDER_COMPONENTS } from '../common/dynamicRenderComponents'

export const COMPONENTS_RENDER = {
  [DYNAMIC_RENDER_COMPONENTS.ATTEDANCE_TEXT]: true,
  [DYNAMIC_RENDER_COMPONENTS.CONTRACT_PDF_DOWNLOAD]: true,
  [DYNAMIC_RENDER_COMPONENTS.CALL_HISTORIC]: true,
  [DYNAMIC_RENDER_COMPONENTS.ADDRESS_CARD_CHANGE_ADDRESS_BUTTON]: true,
  [DYNAMIC_RENDER_COMPONENTS.BANK_SLIPS_CLERK_BUTTON]: true,
  [DYNAMIC_RENDER_COMPONENTS.APPLICATION_REDIRECT]: false,
}
